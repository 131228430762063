var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":_vm.dialogWidth,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('app.buttons.add'))+" "+_vm._s(_vm.$t('app.jornadas.single'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"rounded":"","color":"primary"},on:{"click":_vm.saveItem}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.save'))+" ")])],1)],1),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('form',[_c('v-list',{attrs:{"three-line":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.torneos.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.torneos.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.torneos.single'),"items":_vm.torneos,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},model:{value:(_vm.item.torneo_id),callback:function ($$v) {_vm.$set(_vm.item, "torneo_id", $$v)},expression:"item.torneo_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.canchas.single')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.canchas.single'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.canchas_locales.length === 0,"placeholder":_vm.$t('app.canchas.single'),"items":_vm.canchas_locales,"item-text":"nombre","item-value":"id","error-messages":errors,"required":""},model:{value:(_vm.item.cancha_id),callback:function ($$v) {_vm.$set(_vm.item, "cancha_id", $$v)},expression:"item.cancha_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.local_team')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.local_team'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.equipos_locales.length === 0,"placeholder":_vm.$t('app.headers.local_team'),"items":_vm.equipos_locales,"item-text":"nombre","item-value":"id","error-messages":errors,"required":"","filter":_vm.autocompleteCustomFilter},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.logotipo || _vm.logo}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(data.item.sucursal.nombre)}})],1)]}}],null,true),model:{value:(_vm.item.equipo_local_id),callback:function ($$v) {_vm.$set(_vm.item, "equipo_local_id", $$v)},expression:"item.equipo_local_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.visiting_team')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.visiting_team'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"disabled":_vm.equipos_visitantes.length === 0,"placeholder":_vm.$t('app.headers.visiting_team'),"items":_vm.equipos_visitantes,"item-text":"nombre","item-value":"id","error-messages":errors,"required":"","filter":_vm.autocompleteCustomFilter},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.logotipo || _vm.logo}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(data.item.sucursal.nombre)}})],1)]}}],null,true),model:{value:(_vm.item.equipo_visitante_id),callback:function ($$v) {_vm.$set(_vm.item, "equipo_visitante_id", $$v)},expression:"item.equipo_visitante_id"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.arbitros.name')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.arbitros.name'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"placeholder":_vm.$t('app.arbitros.name'),"items":_vm.arbitros,"item-text":"persona.fullname","item-value":"id","multiple":"","chips":"","deletable-chips":"","clearable":"","error-messages":errors,"required":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('img',{attrs:{"src":data.item.persona.fotografia}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(data.item.persona.fullname)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(data.item.descripcion)}})],1)]}}],null,true),model:{value:(_vm.item.arbitros),callback:function ($$v) {_vm.$set(_vm.item, "arbitros", $$v)},expression:"item.arbitros"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.description')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.description'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"placeholder":_vm.$t('app.headers.description'),"error-messages":errors},model:{value:(_vm.item.descripcion),callback:function ($$v) {_vm.$set(_vm.item, "descripcion", $$v)},expression:"item.descripcion"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.date')))]),_c('v-list-item-subtitle',[_c('v-dialog',{ref:"datePickerModal",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","error-messages":errors,"required":""},model:{value:(_vm.item.fecha),callback:function ($$v) {_vm.$set(_vm.item, "fecha", $$v)},expression:"item.fecha"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.datePickerModal),callback:function ($$v) {_vm.datePickerModal=$$v},expression:"datePickerModal"}},[_c('v-date-picker',{attrs:{"locale":_vm.$store.state.lang,"max":_vm.torneo_seleccionado.fecha_fin},on:{"input":function($event){_vm.datePickerModal = false}},model:{value:(_vm.item.fecha),callback:function ($$v) {_vm.$set(_vm.item, "fecha", $$v)},expression:"item.fecha"}})],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.time')))]),_c('v-list-item-subtitle',[_c('v-dialog',{ref:"timePickerModal",attrs:{"return-value":_vm.item.hora,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.item, "hora", $event)},"update:return-value":function($event){return _vm.$set(_vm.item, "hora", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('app.headers.time'),"prepend-icon":"mdi-clock-outline","readonly":"","error-messages":errors,"required":""},model:{value:(_vm.item.hora),callback:function ($$v) {_vm.$set(_vm.item, "hora", $$v)},expression:"item.hora"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.timePickerModal),callback:function ($$v) {_vm.timePickerModal=$$v},expression:"timePickerModal"}},[(_vm.timePickerModal)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.item.hora),callback:function ($$v) {_vm.$set(_vm.item, "hora", $$v)},expression:"item.hora"}},[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.timePickerModal = false}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.cancel'))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.timePickerModal.save(_vm.item.hora)}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.ok'))+" ")])],1)],1):_vm._e()],1)],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.jornada_de_torneo')))]),_c('v-list-item-subtitle',[_c('validation-provider',{attrs:{"name":_vm.$t('app.headers.jornada_de_torneo'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":_vm.$t('app.headers.jornada_de_torneo'),"error-messages":errors},model:{value:(_vm.item.jornada_de_torneo),callback:function ($$v) {_vm.$set(_vm.item, "jornada_de_torneo", $$v)},expression:"item.jornada_de_torneo"}})]}}],null,true)})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('app.headers.active')))]),_c('v-list-item-subtitle',[_c('v-switch',{staticClass:"pl-5",attrs:{"placeholder":_vm.$t('app.headers.active'),"label":_vm.item.activo ? _vm.$t('app.general.yes') : _vm.$t('app.general.no')},model:{value:(_vm.item.activo),callback:function ($$v) {_vm.$set(_vm.item, "activo", $$v)},expression:"item.activo"}})],1)],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }