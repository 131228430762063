<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar>
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.jornadas.single') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.torneos.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.torneos.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.torneo_id"
                        :placeholder="$t('app.torneos.single')"
                        :items="torneos"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.canchas.single') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.canchas.single')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.cancha_id"
                        :disabled="canchas_locales.length === 0"
                        :placeholder="$t('app.canchas.single')"
                        :items="canchas_locales"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.local_team') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.local_team')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.equipo_local_id"
                        :disabled="equipos_locales.length === 0"
                        :placeholder="$t('app.headers.local_team')"
                        :items="equipos_locales"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                        :filter="autocompleteCustomFilter"
                      >
                        <template v-slot:item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.logotipo || logo">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.nombre" />
                            <v-list-item-subtitle v-text="data.item.sucursal.nombre" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.visiting_team') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.visiting_team')"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="item.equipo_visitante_id"
                        :disabled="equipos_visitantes.length === 0"
                        :placeholder="$t('app.headers.visiting_team')"
                        :items="equipos_visitantes"
                        item-text="nombre"
                        item-value="id"
                        :error-messages="errors"
                        required
                        :filter="autocompleteCustomFilter"
                      >
                        <template v-slot:item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.logotipo || logo">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.nombre" />
                            <v-list-item-subtitle v-text="data.item.sucursal.nombre" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.arbitros.name') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.arbitros.name')"
                      rules=""
                    >
                      <v-autocomplete
                        v-model="item.arbitros"
                        :placeholder="$t('app.arbitros.name')"
                        :items="arbitros"
                        item-text="persona.fullname"
                        item-value="id"
                        multiple
                        chips
                        deletable-chips
                        clearable
                        :error-messages="errors"
                        required
                      >
                        <template v-slot:item="data">
                          <v-list-item-avatar>
                            <img :src="data.item.persona.fotografia">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="data.item.persona.fullname" />
                            <v-list-item-subtitle v-text="data.item.descripcion" />
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.description') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.description')"
                      rules=""
                    >
                      <v-textarea
                        v-model="item.descripcion"
                        :placeholder="$t('app.headers.description')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.date') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-dialog
                      ref="datePickerModal"
                      v-model="datePickerModal"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.date')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.fecha"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            :error-messages="errors"
                            required
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
<!--                      <v-date-picker
                        v-model="item.fecha"
                        :locale="$store.state.lang"
                        :min="torneo_seleccionado.fecha_inicio"
                        :max="torneo_seleccionado.fecha_fin"
                        @input="datePickerModal = false"
                      />-->
                      <v-date-picker
                        v-model="item.fecha"
                        :locale="$store.state.lang"
                        :max="torneo_seleccionado.fecha_fin"
                        @input="datePickerModal = false"
                      />
                    </v-dialog>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.time') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-dialog
                      ref="timePickerModal"
                      v-model="timePickerModal"
                      :return-value.sync="item.hora"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('app.headers.time')"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.hora"
                            :label="$t('app.headers.time')"
                            prepend-icon="mdi-clock-outline"
                            readonly
                            v-bind="attrs"
                            :error-messages="errors"
                            required
                            v-on="on"
                          />
                        </validation-provider>
                      </template>
                      <v-time-picker
                        v-if="timePickerModal"
                        v-model="item.hora"
                        full-width
                      >
                        <v-col class="d-flex justify-center">
                          <v-btn
                            text
                            color="primary"
                            @click="timePickerModal = false"
                          >
                            {{ $t('app.buttons.cancel') }}
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.timePickerModal.save(item.hora)"
                          >
                            {{ $t('app.buttons.ok') }}
                          </v-btn>
                        </v-col>
                      </v-time-picker>
                    </v-dialog>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.jornada_de_torneo') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('app.headers.jornada_de_torneo')"
                      rules=""
                    >
                      <v-text-field
                        v-model="item.jornada_de_torneo"
                        :placeholder="$t('app.headers.jornada_de_torneo')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ $t('app.headers.active') }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-switch
                      v-model="item.activo"
                      class="pl-5"
                      :placeholder="$t('app.headers.active')"
                      :label="item.activo ? $t('app.general.yes') : $t('app.general.no')"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import autocompleteCustomFilter from "../../../../plugins/customFilter";

  export default {
    name: 'JornadasAdd',
    mixins: [autocompleteCustomFilter],
    data () {
      return {
        dialog: false,
        item: {
          torneo_id: null,
          equipo_local_id: null,
          equipo_visitante_id: null,
          cancha_id: null,
          fecha: new Date().toISOString().substr(0, 10),
          hora: null,
          descripcion: null,
          activo: null,
          arbitros: [],
          jornada_de_torneo: ''
        },
        equipos: [],
        torneos: [],
        canchas: [],
        arbitros: [],
        datePickerModal: false,
        timePickerModal: false,
      }
    },
    computed: {
      torneo_seleccionado () {
        const selected = this.torneos.filter(torneo => torneo.id === this.item.torneo_id)
        if (selected.length > 0) {
          return selected[0]
        } else {
          return {
            sucursal_id: 0,
            fecha_inicio: null,
            fecha_fin: null,
          }
        }
      },
      equipos_locales () {
        return this.equipos.filter(equipo => equipo.sucursal_id === this.torneo_seleccionado.sucursal_id)
      },
      equipos_visitantes () {
        return this.equipos.filter(equipo => equipo.sucursal_id === this.torneo_seleccionado.sucursal_id && equipo.id != this.item.equipo_local_id)
      },
      canchas_locales () {
        return this.canchas.filter(cancha => cancha.sucursal_id === this.torneo_seleccionado.sucursal_id)
      },
    },
    mounted () {
      EventBus.$on('jornadas-add', async () => {
        this.toggleLoader()

        this.equipos = await this.getEquipos()
        this.torneos = await this.getTorneos()
        this.canchas = await this.getCanchas()
        this.arbitros = await this.getArbitros()

        this.toggleLoader()
        this.dialog = true
      })
    },
    methods: {
      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            this.item.sucursal_id = this.torneo_seleccionado.sucursal_id

            await this.$http.post(route('v1/journey'), this.item)
              .then(response => {
                if (response.body.code === 200) {
                  this.processSuccess(response)
                  this.item = {
                    torneo_id: null,
                    equipo_local_id: null,
                    equipo_visitante_id: null,
                    cancha_id: null,
                    fecha: new Date().toISOString().substr(0, 10),
                    hora: null,
                    descripcion: null,
                    activo: null,
                    arbitros: [],
                    jornada_de_torneo: ''
                  }

                  requestAnimationFrame(() => {
                    this.$refs.observer.reset()
                  })

                  this.dialog = false
                  EventBus.$emit('reload-items')
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
